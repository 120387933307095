<form [formGroup]="formGroup" fxLayout="row wrap" fxLayoutGap="15px grid" cdkFocusInitial>
    <ng-container *ngFor="let element of formConfig.elements; let i = index; trackBy: trackByIndex">
        <div
            class="form-group"
            *ngIf="element.type === 'group' && showElement(element)"
            fxFlex="{{ element.width ? element.width : 100 }}"
            fxLayout="row wrap"
            fxLayoutGap="15px grid">
            <div
                class="elements"
                fxFlex="{{ element.width ? element.width : 100 }}"
                fxLayout="row wrap"
                fxLayoutGap="15px grid">
                <div
                    *ngFor="let child of element.properties; let k = index; trackBy: trackByName"
                    class="form-element form-element-{{ child.type }}"
                    id="{{ child.name }}"
                    matTooltipPosition="before"
                    fxFlex="{{ child.width ? child.width : 100 }}">
                    <elias-form-element
                        [attr.data-cy]="'form-element-' + child.name"
                        [formGroup]="formGroup"
                        [element]="child"
                        [translationKey]="translationKey"
                        [disabledOptions]="disabledOptions"
                        [hint]="hint"
                        [settings]="settings"
                        [initValue]="initData[child.name] ? initData[child.name] : null"
                        *ngIf="showElement(child)"></elias-form-element>
                </div>
            </div>
        </div>
        <div
            class="form-element form-element-{{ element.type }}"
            *ngIf="element.type !== 'group'"
            id="{{ element.name }}"
            matTooltipPosition="before"
            fxFlex="{{ element.width ? element.width : 100 }}">
            <elias-form-element
                [attr.data-cy]="'form-element-' + element.name"
                [formGroup]="formGroup"
                [element]="element"
                [translationKey]="translationKey"
                [disabledOptions]="disabledOptions"
                [hint]="hint"
                [settings]="settings"
                [initValue]="initData[element.name] ? initData[element.name] : null"
                *ngIf="showElement(element)"></elias-form-element>
        </div>
    </ng-container>
    <div style="clear: both" *ngIf="!hideButtons">
        <button mat-flat-button color="accent" [disabled]="!canSave()" (click)="onSubmit()" *ngIf="!hideSaveButton">
            {{ 'actions.save' | translate }}
        </button>
        <button mat-flat-button color="primary" [disabled]="!isDirty()" (click)="onReset()" *ngIf="!hideResetButton">
            {{ 'actions.reset' | translate }}
        </button>
    </div>

    <!--    {{formGroup.value | json}}-->
    <!--    {{getFormValidationErrors() | json}}-->
</form>

<div class="comments-wrapper">
    <div class="container">
        <elias-shared-sidebar-title [title]="title ?? ''"></elias-shared-sidebar-title>
        <elias-comment-list-filters
            *ngIf="showFilters"
            (filtersChanged)="onFiltersChanged()"></elias-comment-list-filters>
        <elias-shared-loading *ngIf="(loaded$ | async) === false"></elias-shared-loading>
    </div>
    <ng-container *ngIf="loaded$ | async">
        <div class="comment-list">
            <div
                class="container"
                (lastItemReached)="loadMoreComments()"
                eliasInfiniteScrollingContainer
                eliasScrollableContainer>
                <elias-comment
                    *ngFor="let comment of comments$ | async; let commentIndex = index; trackBy: trackCommentById"
                    data-cy="comment"
                    [comment]="comment"
                    [status]="commentStatus"
                    [isSelected]="(selectedComment$ | async)?.id === comment.id"
                    [isReplied]="(selectedComment$ | async)?.id === comment.id && editorAction === 'reply'"
                    [showControls]="showControls"
                    [id]="comment.id"
                    (commentResolved)="onCommentResolved($event)"
                    (commentUnresolved)="onCommentUnresolved($event)"
                    (commentLinkClicked)="onCommentLinkClicked($event)"
                    (answerClicked)="onAnswerClicked($event)"
                    eliasInfiniteScrollingItem
                    eliasScrollableItem></elias-comment>
                <elias-shared-placeholder
                    [message]="'comments.empty' | translate"
                    *ngIf="!(comments$ | async)?.length"></elias-shared-placeholder>
            </div>
        </div>
        <elias-comment-editor
            *ngIf="commentStatus === CommentStatusEnum.Editable"
            [action]="editorAction"
            [initialContent]="initialContent"
            (commentCancelled)="onCommentCancelled()"
            (commentSubmitted)="onCommentSubmitted($event)"
            (commentDraftUpdated)="onCommentDraftUpdated($event)"></elias-comment-editor>
    </ng-container>
</div>

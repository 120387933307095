<ul class="breadcrumbs">
    <li *ngFor="let breadcrumb of breadcrumbList; let last = last; let i = index; trackBy: trackBreadcrumbByUrl">
        <ng-container *ngIf="breadcrumb.title && breadcrumb.url">
            <a [routerLink]="breadcrumb.url" routerLinkActive="active" *ngIf="!last; else lastBreadcrumb">{{
                breadcrumb.title | translate | strlen : 25
            }}</a>

            <ng-template #lastBreadcrumb>
                <div>{{ breadcrumb.title | translate | strlen : 25 }}</div>
            </ng-template>
        </ng-container>
    </li>
</ul>

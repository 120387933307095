<div class="asset-view-container">
    <div fxLayout="row" class="content" fxLayoutGap="20px">
        <ng-container *ngIf="asset">
            <div class="thumbnail-wrapper" [ngClass]="{ clickable: isThumbnailOverlayEnabled }">
                <elias-asset-thumbnail
                    class="thumbnail"
                    [asset]="asset"
                    [allowFallbackImage]="true"
                    (click)="onThumbnailClicked()"
                    iconSize="big"></elias-asset-thumbnail>

                <div class="thumbnail-overlay" *ngIf="isThumbnailOverlayEnabled">
                    <ng-content select="[thumbnailOverlay]"></ng-content>
                </div>
            </div>

            <div>
                <table>
                    <tr>
                        <td>{{ 'assetBrowser.assetView.name' | translate }}:</td>
                        <td>{{ asset.name }}</td>
                    </tr>
                    <tr>
                        <td>{{ 'assetBrowser.assetView.createdAt' | translate }}:</td>
                        <td>
                            {{ asset.createdAt | localizedDate : 'eliasVerboseDateTime' }}
                        </td>
                    </tr>
                    <tr>
                        <td>{{ 'assetBrowser.assetView.updatedAt' | translate }}:</td>
                        <td>
                            {{ asset.updatedAt | localizedDate : 'eliasVerboseDateTime' }}
                        </td>
                    </tr>
                    <tr *ngIf="isExcel(asset)">
                        <td>{{ 'assetBrowser.assetView.excel.processedAt' | translate }}:</td>
                        <td *ngIf="asset.processedAt === null && asset.processing === false">
                            {{ 'assetBrowser.assetView.excel.waitingForProcessing' | translate }}
                        </td>
                        <td *ngIf="asset.processing === true">
                            {{ 'assetBrowser.assetView.excel.processing' | translate }}
                        </td>
                        <td *ngIf="asset.processedAt !== null && asset.processing === false">
                            {{ asset.processedAt | localizedDate : 'eliasVerboseDateTime' }}
                        </td>
                    </tr>
                    <tr>
                        <td>{{ 'assetBrowser.assetStorage' | translate }}:</td>
                        <td *ngIf="asset.storage.type === 'publication-group'">
                            {{ 'assetBrowser.assetstorages.publication-group' | translate }}
                        </td>
                        <td *ngIf="asset.storage.type === 'general'">
                            {{ 'assetBrowser.assetstorages.general' | translate }}
                        </td>
                        <td *ngIf="asset.storage.type === 'shared'">{{ asset.storage.name }}</td>
                    </tr>
                </table>
            </div>
        </ng-container>
    </div>

    <div *ngIf="processingErrorMessages.length > 0">
        <mat-accordion class="processing-error">
            <mat-expansion-panel class="processing-error-panel">
                <mat-expansion-panel-header class="processing-error-header">
                    <mat-panel-title class="processing-error-title">
                        <i class="ei ei-error-full error-icon"></i>
                        <p>
                            <span class="bold">{{ 'assetBrowser.assetView.excel.processingError' | translate }}: </span>
                            {{ 'assetBrowser.assetView.excel.showMore' | translate }}
                        </p>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="processing-error-details">
                    <div
                        class="worksheet-errors"
                        *ngFor="let worksheet of processingErrorMessages; trackBy: trackWorksheetByName">
                        <div class="bold">{{ worksheet.name }}</div>
                        <div>{{ worksheet.error }}</div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>

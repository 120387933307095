<div
    [dropzone]="config"
    (processing)="onUploadInit()"
    (queueComplete)="onUploadComplete()"
    (error)="onUploadError($event)"
    (success)="onSuccess($event)"
    (dragenter)="onDragEnter($event)"
    (drop)="onDrop($event)"
    (dragleave)="onDragLeave($event)"
    (dragOver)="onDragOver($event)"
    class="dropzone-container">
    <div class="upload-panel error-panel" [@slideInOut]="showErrorPanel ? 'in' : 'out'">
        <div class="close" (click)="setShowErrorPanel(false)"><i class="ei ei-close"></i></div>
        <div class="inner">
            <strong> {{ 'assetBrowser.upload.error.title' | translate }}</strong>
            <ul>
                <li *ngFor="let file of uploadErrorFiles; trackBy: trackFileByName">
                    <strong>{{ file.name }}:</strong> {{ file.error }}
                </li>
            </ul>
        </div>
    </div>
    <div class="upload-panel success-panel" [@slideInOut]="showSuccessPanel ? 'in' : 'out'">
        <div class="close" (click)="setShowErrorPanel(false)"><i class="ei ei-close"></i></div>
        <div class="inner">
            <strong>{{ 'assetBrowser.upload.success' | translate }}</strong>
        </div>
    </div>
    <ng-content></ng-content>
</div>

<div *ngIf="!loading">
    <div class="pe-preset-group" *ngFor="let group of presetGroups$ | async; trackBy: trackGroupByName">
        <div *ngIf="group.data.length > 0">
            <div
                class="pe-preset-container"
                [dragula]="'bag-draggable'"
                fxLayout="row wrap"
                fxLayoutAlign="space-between center">
                <div
                    id="draggable"
                    class="pe-preset"
                    fxFlex="0 1"
                    [attr.data-cy]="'preset-browser-node-' + group.name | lowercase"
                    [attr.data-items]="group.data | json"
                    [attr.data-presetGroup]="group.name"
                    fxLayoutGap="10px">
                    <i class="ei {{ group.icon }} ei-150"></i>
                    <span class="label">{{ 'presetBrowser.' + group.name | translate }}</span>
                </div>
            </div>
        </div>
        <br />
    </div>
</div>

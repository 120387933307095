import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Asset } from '../../models/asset.model';
import { ProcessingErrorMessage } from '../../models/processing-error-message.model';
import { Excel } from '../../../../editor/editor/models/excel.model';
import { SpreadsheetTypes } from '../../../../editor/asset-browser/models/spreadsheet-types.model';

@Component({
    selector: 'elias-shared-asset-details',
    templateUrl: './asset-details.component.html',
    styleUrls: ['./asset-details.component.scss'],
})
export class AssetDetailsComponent {
    @Input() asset?: Asset;
    @Input() isThumbnailOverlayEnabled: boolean = false;
    @Output() thumbnailClicked = new EventEmitter<void>();

    get processingErrorMessages(): ProcessingErrorMessage[] {
        if (!this.asset || !this.isExcel(this.asset) || !this.asset.processingErrorMessages) {
            return [];
        }

        return Object.values(this.asset.processingErrorMessages);
    }

    public isExcel(asset: Asset): asset is Excel {
        return SpreadsheetTypes.includes(asset.mimeType);
    }

    public onThumbnailClicked(): void {
        if (!this.isThumbnailOverlayEnabled) {
            return;
        }

        this.thumbnailClicked.emit();
    }

    public trackWorksheetByName(index: number, worksheet: ProcessingErrorMessage): string {
        return worksheet.name;
    }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { KeyValueOption } from '../../models/key-value-option.model';

@Component({
    selector: 'elias-shared-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent {
    protected _selectedValue: any = null;
    get selectedValue(): any {
        return this._selectedValue;
    }

    @Input()
    set selectedValue(value: any) {
        this._selectedValue = value;
    }

    @Input() defaultOptionText: string = '';
    @Input() options: KeyValueOption[] = [];
    @Input() title: string = '';

    @Output() changeValue = new EventEmitter<any>();

    get selectTriggerLabel(): string {
        if (this.isValueSelected()) {
            const option = this.options.find((option) => option.key === this.selectedValue);
            return option ? option.value : this.title;
        }

        return this.title;
    }

    reset(event: any) {
        event.stopPropagation();
        this._selectedValue = null;
        this.changeValue.emit(null);
    }

    onClick(event: any, option: KeyValueOption) {
        this.changeValue.emit(option.key);
    }

    isValueSelected() {
        return !!this._selectedValue;
    }

    public trackByKey(index: number, option: KeyValueOption): string {
        return option.key;
    }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Asset } from '../../../../modules/shared/models/asset.model';
import { AssetsService } from '../../../../modules/shared/services/assets.service';
import { Excel } from '../../../editor/models/excel.model';
import { AssetCollectionsQuery } from '../../../../modules/shared/state/asset-collections/asset-collections.query';

@Component({
    selector: 'elias-asset-browser-file',
    templateUrl: './asset-browser-file.component.html',
    styleUrls: ['./asset-browser-file.component.scss'],
})
export class AssetBrowserFileComponent {
    @Input() asset?: Asset;
    @Input() isSelected: boolean = false;
    @Input() isSelectable: boolean = false;
    @Input() isListView: boolean = false;

    @Output() assetClicked = new EventEmitter<Asset>();

    constructor(public assetCollectionsQuery: AssetCollectionsQuery, private assetsService: AssetsService) {}

    get isImage(): boolean {
        if (!this.asset) {
            return false;
        }

        return this.assetsService.isImage(this.asset);
    }

    get isPdf(): boolean {
        if (!this.asset) {
            return false;
        }

        return this.assetsService.isPdf(this.asset);
    }

    get isExcel(): boolean {
        if (!this.asset) {
            return false;
        }

        return this.assetsService.isExcel(this.asset);
    }

    get hasErrors(): boolean {
        return this.assetsService.hasError(this.asset);
    }

    get isBeingProcessed(): boolean {
        if (this.asset && this.isExcel) {
            return (this.asset as Excel).processedAt === null;
        }

        return false;
    }

    public onClick(): void {
        this.assetClicked.emit(this.asset);
    }

    public trackByAssetCollection(index: number, assetCollection: string): string {
        return assetCollection;
    }
}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SectionUsingAsset } from '../../../modules/shared/models/section-using-asset.model';

@Component({
    selector: 'cropping-asset-confirmation',
    styleUrls: ['./cropping-asset-confirmation.component.scss'],
    templateUrl: './cropping-asset-confirmation.component.html',
})
export class CroppingAssetConfirmationComponent {
    public publicationsUsingAsset: SectionUsingAsset[];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<CroppingAssetConfirmationComponent>
    ) {
        this.publicationsUsingAsset = data['listOfPublications'];
    }

    public onConfirm(replaceImage: boolean): void {
        this.dialogRef.close(replaceImage);
    }

    public onCancel(): void {
        this.dialogRef.close();
    }

    public trackBySectionId(index: number, publicationUsingAsset: SectionUsingAsset): string {
        return publicationUsingAsset.sectionId;
    }
}

<ng-container *ngFor="let channelId of channelIds; trackBy: trackByChannelId">
    <div class="properties-diff-row" *ngIf="channelSettings">
        <div class="properties-diff-cell properties-diff-cell-header">
            {{ 'versioning.diff.channelSettings' | translate }} - {{ getChannelName(channelId) | titlecase }}
        </div>
        <div class="properties-diff-cell">
            {{ 'versioning.diff.' + getChannelSettingStatus(channelSettings, channelId).before | translate }}
        </div>
        <div class="properties-diff-cell">
            {{ 'versioning.diff.' + getChannelSettingStatus(channelSettings, channelId).after | translate }}
        </div>
    </div>
</ng-container>

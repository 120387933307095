<elias-base-comment
    [comment]="comment"
    [status]="status"
    (commentClicked)="onCommentClicked()"
    [ngClass]="{
        'resolved-comment': comment.resolvedAt,
        'default-action': hasDefaultAction,
        'selected-comment': isSelected,
        'replied-comment': isReplied
    }">
    <ng-container actions>
        <elias-comment-action-editable
            [comment]="comment"
            [showControls]="showControls"
            (commentResolved)="onCommentResolved()"
            (commentUnresolved)="onCommentUnresolved()"
            (commentReplied)="onCommentReplied()"
            *ngIf="status === CommentStatusEnum.Editable"></elias-comment-action-editable>

        <elias-comment-action-readonly
            [comment]="comment"
            [showControls]="showControls"
            (commentLinkClicked)="onCommentLinkClicked()"
            *ngIf="status === CommentStatusEnum.ReadOnly"></elias-comment-action-readonly>
    </ng-container>
</elias-base-comment>

<div class="replies">
    <div *ngFor="let reply of comment.replies; trackBy: trackReplyById" class="reply-container">
        <elias-reply
            [reply]="reply"
            [status]="status"
            [isResolved]="!!comment.resolvedAt"
            [showControls]="showControls"
            (replyLinkClicked)="onReplyLinkClicked()"></elias-reply>
    </div>
</div>

<elias-base-dialog [showCloseButton]="false" (dialogClosed)="onCancel()">
    <h1 header class="dialog-header">{{ 'assetBrowser.imageCropping.warning' | translate }}</h1>

    <div class="body">
        <p>{{ 'assetBrowser.imageCropping.listOfUsedPublications' | translate }}</p>
        <div class="publication-list">
            <div
                class="publication-name"
                *ngFor="let publicationUsingAsset of this.publicationsUsingAsset; trackBy: trackBySectionId">
                {{ publicationUsingAsset.publicationName }}
            </div>
        </div>
        <p>{{ 'assetBrowser.imageCropping.replaceImageMessage' | translate }}</p>
        <p>{{ 'assetBrowser.imageCropping.saveAsCopyMessage' | translate }}</p>
    </div>

    <div footer class="footer-bar">
        <button mat-stroked-button (click)="onCancel()" class="modal-cancel-button" data-karma="cancel-button">
            {{ 'actions.cancel' | translate }}
        </button>
        <div class="confirm-buttons">
            <button
                mat-stroked-button
                color="warn"
                (click)="onConfirm(true)"
                class="modal-save-button"
                data-karma="replace-button">
                {{ 'assetBrowser.imageCropping.replaceImage' | translate }}
            </button>
            <button
                mat-flat-button
                cdkFocusInitial
                color="warn"
                (click)="onConfirm(false)"
                class="modal-save-button save-as-copy-button"
                data-karma="save-as-copy-button">
                {{ 'assetBrowser.imageCropping.saveAsCopy' | translate }}
            </button>
        </div>
    </div>
</elias-base-dialog>

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Creators } from '../../../../modules/shared/models/creator.model';
import { UsersQuery } from '../../../../modules/shared/state/users/users.query';
import { VersionsService } from '../../services/versions.service';
import { Version } from '../../models/version.model';
import { Observable } from 'rxjs';
import { QueryParameters } from '../../../../modules/core/models/query-parameters.model';
import { switchMap, tap } from 'rxjs/operators';
import { SideNavService } from '../../../../modules/core/services/side-nav.service';
import { RestoreVersionComponent } from '../../dialogs/restore-version/restore-version.component';
import { MatDialog } from '@angular/material/dialog';
import { DisplayChangesComponent } from '../../dialogs/display-changes/display-changes.component';
import { Log } from '../../../history/models/log.model';
import { VersionDiff } from '../../models/version-diff.model';

@Component({
    selector: 'elias-version-list',
    templateUrl: './version-list.component.html',
    styleUrls: ['./version-list.component.scss'],
})
export class VersionListComponent implements OnInit, OnDestroy {
    @Input() nodeId?: string;

    public creators: Creators = {};
    public loading = false;
    public versions$: Observable<Version[]>;

    public currentPage = 1;
    public hasNextPage = true;

    constructor(
        public dialog: MatDialog,
        private sideNavService: SideNavService,
        private usersQuery: UsersQuery,
        private versionsService: VersionsService
    ) {
        this.creators = this.usersQuery.getCreators();
        this.versions$ = this.versionsService.versions$;
    }

    ngOnInit(): void {
        this.fetchVersions();
    }

    public async loadMoreVersions(): Promise<void> {
        if (this.hasNextPage) {
            const nextPage = this.currentPage + 1;
            const options = this.versionsService.getCurrentRequestOptions(nextPage);
            this.fetchVersions(options);
        }
    }

    public restoreNodeVersion(versionId: string): void {
        if (!this.nodeId) {
            return;
        }

        this.versionsService
            .restoreNodeVersion(this.nodeId, versionId)
            .pipe(
                tap(async () => {
                    await this.sideNavService.close();
                })
            )
            .subscribe();
    }

    public fetchVersions(options: QueryParameters = {}) {
        if (!this.nodeId) {
            return;
        }

        this.loading = true;
        this.versionsService.getVersions(this.nodeId, options).subscribe((data) => {
            this.currentPage = data.currentPage;
            this.hasNextPage = data.hasNextPage;

            this.loading = false;
        });
    }

    public displayChanges(log: Log) {
        const nodeId = this.nodeId;
        if (!nodeId) {
            return;
        }

        this.versionsService
            .getDiffForNodeAndVersion(nodeId, log.id)
            .pipe(
                switchMap((diff) => {
                    const dialogRef = this.dialog.open(DisplayChangesComponent, {
                        width: '800px',
                        height: '650px',
                        autoFocus: true,
                        data: { diff, log },
                    });

                    return dialogRef.beforeClosed().pipe(
                        switchMap((result) =>
                            result === true ? this.versionsService.getDiffForNodeAndVersion(nodeId, log.id, true) : []
                        ),
                        tap((restoreDiff) => {
                            if (restoreDiff) {
                                this.openVersionDiff(log, restoreDiff);
                            }
                        })
                    );
                })
            )
            .subscribe();
    }

    public openVersionDiff(log: Log, diff: VersionDiff) {
        if (!this.nodeId) {
            return;
        }

        const dialogRef = this.dialog.open(RestoreVersionComponent, {
            width: '800px',
            height: '650px',
            autoFocus: true,
            data: { diff, log },
        });

        return dialogRef
            .afterClosed()
            .pipe(
                tap((result) => {
                    if (result === true) {
                        this.restoreNodeVersion(log.id);
                    }
                })
            )
            .subscribe();
    }

    public trackVersionByLogId(index: number, version: Version): string {
        return version.log.id;
    }

    ngOnDestroy(): void {
        this.versionsService.resetStore();
    }
}
